import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import axios from "axios";
import domain from "@/environment";
import user from "./models/user";
import Pays from "../models/pays";
import products from "./models/product";
import prestation from "./models/prestation";
import ficheClient from "./models/ficheClients";
import piece from "./models/peice";
import travaux from "./models/travaux";
import Router from "../router/index";
import User from "../models/user";
import client from "./models/client";
import clientType from "./models/clientType";
import modele from "./models/modele";
import agenda from "./models/agenda";
import langue from "./models/langue";
import dictionnaire from "./models/dictionnaire";
import multiLangues from "./models/multilangue";
import devis from "./models/devis";
import entreprise from "./models/entreprise";
import facture from "./models/facture";
import gallery from "./models/gallery";
import CategorieFichier from "./models/CategorieFichier";
import delegataire from "./models/delegataire";
import notification from "./models/notification";
import Forfait from "./models/forfait";
import Mainoeuvre from "./models/mainoeuvre";
import country from "./models/country";
import categorieArticle from "./models/categorieArticle";
import categorieMetier from "./models/categorieMetier";
import Search from "./models/Search";
import Avoir from "./models/avoir";
import sousTraitant from "./models/sousTraitant";
import dossier from "./models/dossier";
import entiterExtern from './models/entiterExtern';
import rge from './models/rge';
import logement from "./models/logement";
import systemeChauffage from "./models/systemeChauffage";
import chauffeEau from "./models/chauffeEau";
import devisMonoFiche from "./models/devisMonoFiche";
import devis_group_produit from "./models/devis_group_produit";
import groupes from "./models/groupes";
import organism from "./models/organism";
import menages from "./models/menages";
Vue.use(Vuex);

export default createStore(Vuex.Store, {
  state: {
    token: window.localStorage.getItem("access_token"),
    showTabs: false,
    selectedLang: localStorage.getItem("SelectedLang")
      ? JSON.parse(localStorage.getItem("SelectedLang"))
      : { iso_code: "fr", lib: "francais" },
    pays: null,
    error: null,
    showError: false,
    emailVerif: null,
  },
  mutations: {
    SAVE_LANG(state, payload) {
      state.selectedLang = payload;
    },
    SET_TOKEN(state, payload) {
      if (payload) {
        window.localStorage.setItem("access_token", payload);
        state.token = payload;
      } else {
        state.token = null;
      }
    },
    SET_COUNTRY(state, payload) {
      state.pays = payload.map((item) => Pays.create(item));
    },
    SET_COUNTRY_LANG(state, payload) {
      state.pays = state.pays.map((p) => {
        return p.format(payload);
      });
    },
    SET_ERROR(state, payload) {
      if (payload) state.error = payload.message;
      else state.error = null;
    },
  },
  actions: {
    saveLang({ commit }, payload) {
      window.localStorage.setItem("SelectedLang", JSON.stringify(payload));
      this.dispatch("dictionnaire", payload);
      commit("SAVE_LANG", payload);
    },
    changeTabs() {
      this.state.showTabs = !this.state.showTabs;
    },

    async login({ commit }, payload) {
      try {
        const responce = await axios.post(domain + `/login`, {
          email_u: payload.email_u,
          password: payload.password,
        });
        commit("SET_TOKEN", responce.data.data.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify(User.create(responce.data.data.user))
        );

        commit("SET_USER", responce.data.data.user);
        return true;
      } catch (err) {
        return err.response.data;
      }
    },
    async logout({ commit }) {
      commit("DO_NOTHING");
      try {
        await axios.post(
          domain + `/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ` + this.state.token,
            },
          }
        );
        this.reset();
        return true;
      } catch (error) {
        this.reset();
        return error.response.data.message;
      } finally {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        Router.replace("/login");
        Router.go();
      }
    },

    changeLang({ commit }, payload) {
      window.localStorage.setItem("SelectedLang", JSON.stringify(payload));
      this.dispatch("translation");
      commit("SAVE_LANG", payload);
    },

    async pays({ commit }, payload) {
      try {
        const responce = await axios.get(`https://restcountries.com/v2/all`);
        commit("SET_COUNTRY", responce.data);
        commit("SET_COUNTRY_LANG", payload);
        return true;
      } catch (error) {
        return error.response.data.message;
      }
    },
  },

  getters: {
    getToken: (state) => state.token,
    getTabs: (state) => state.showTabs,
    getDomain: () => "http://api.batigo.fr/",
    getSelectedLang: (state) => state.selectedLang,
    getPays: (state) => state.pays,
    getErreur: (state) => state.error,
    getShowErreur: (state) => state.showError,
    isEmailVerif: (state) => state.emailVerif,
  },
  modules: {
    user,
    products,
    prestation,
    piece,
    travaux,
    client,
    clientType,
    modele,
    langue,
    dictionnaire,
    devis,
    entreprise,
    facture,
    gallery,
    agenda,
    ficheClient,
    CategorieFichier,
    notification,
    Forfait,
    Mainoeuvre,
    delegataire,
    country,
    categorieArticle,
    categorieMetier,
    Search,
    Avoir,
    sousTraitant,
    multiLangues,
    dossier,
    entiterExtern,
    rge,
    logement,
    systemeChauffage,
    chauffeEau,
    devisMonoFiche,
    devis_group_produit,
    groupes,
    organism,
    menages
  },
});
