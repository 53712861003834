import domain from "@/environment";
import axios from "axios";

const state = {
  menage: null,
  menages: [],
  menageRows: 0,
  menageLoading: false,
};

const getters = {
  getMenage: (state) => state.menage,
  getAllMenages: (state) => state.menages,
  getTotalRowMenage: (state) => state.menageRows,
  getLoadingMenage: (state) => state.menageLoading,
};

const mutations = {
  DO_NOTHING() {},
  SET_LOADINGMENAGES(state, payload) {
    state.menageLoading = payload || false;
  },
  SET_MENAGE(state, payload) {
    state.menage = payload || null;
  },
  PUSH_MENAGE(state, payload) {
    state.menages.push(payload);
  },
  UPDATE_MENAGE(state, payload) {
    const index = state.menages.findIndex((l) => l.id == payload.id);
    if (index !== -1) {
      state.menages[index] = { ...state.menages[index], ...payload };
    }
  },
  UPDATE_MENAGE_ROW(state, payload) {
    state.menageRows = payload || 0;
  },
  DELETE_MENAGES(state, payload) {
    state.menages = state.menages.filter((l) => l.id != payload.id);
  },
  SET_MENAGES(state, payload) {
    state.menages = payload || [];
  },
};

const actions = {
  async allMenage({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + `/menage`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_MENAGES", response.data.data.data);
      commit("UPDATE_MENAGE_ROW", response.data.data.total);
      return response.data.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getMenage({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/menage/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_MENAGE", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async storeMenage({ commit }, payload) {
    commit("SET_LOADINGMENAGES", true);
    try {
      const response = await axios.post(domain + `/menage`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_MENAGE", response.data.data);
      commit("SET_LOADINGMENAGES", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADINGMENAGES", false);
      return error.response.data.message;
    }
  },
  async editMenage({ commit }, payload) {
    commit("SET_LOADINGMENAGES", true);
    try {
      const response = await axios.put(
        domain + `/menage/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_MENAGE", response.data.data);
      commit("SET_LOADINGMENAGES", false);
      return response.data.data;
    } catch (error) {
      commit("SET_LOADINGMENAGES", false);
      console.error("Erreur réseau ou API :", error);
      return error.response ? error.response.data.message : error.message;
    }
  },
  async deleteMenage({ commit }, payload) {
    commit("SET_LOADINGMENAGES", true);
    try {
      await axios.delete(domain + `/menage/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_MENAGES", payload);
      commit("SET_LOADINGMENAGES", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGMENAGES", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
